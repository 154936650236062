<template>
  <div class="trust">
    <div class="topPart">
      <div class="form-item">
        <div class="inputBox"
             style="margin-right:10px;">
          <div class="title">企业名称</div>
          <Input v-model="params.qyjc"
                 placeholder="模糊搜索"
                 style="margin-right:50px; width:300px;" />
        </div>
        <div class="inputBox"
             style="margin-right:10px;">
          <div class="title">股票代码</div>
          <Input v-model="params.gpdm"
                 placeholder="模糊搜索"
                 style="margin-right:50px; width:300px;" />
        </div>
      </div>
      <div class="form-item">
        <span class="title">公告时间</span>
        <DatePicker :type="'year'"
                    @on-change="getTimeStart"
                    v-model="params.ggrq[0]"
                    format="yyyy"
                    placeholder="开始时间"
                    style="width: 140px; margin-right:5px;">
        </DatePicker>
        至
        <DatePicker :type="'year'"
                    v-model="params.ggrq[1]"
                    @on-change="getTimeEnd"
                    format="yyyy"
                    placeholder="结束时间"
                    style="width: 140px;margin:0 50px 0 5px;">
        </DatePicker>
        <span class="title">融资日期</span>
        <DatePicker :type="'year'"
                    format="yyyy"
                    @on-change="getTimeStartrz"
                    v-model="params.sjrzrq[0]"
                    placeholder="开始时间"
                    style="width: 140px; margin-right:5px;">
        </DatePicker>
        至
        <DatePicker :type="'year'"
                    format="yyyy"
                    v-model="params.sjrzrq[1]"
                    @on-change="getTimeEndrz"
                    placeholder="结束时间"
                    style="width: 140px;margin:0 50px 0 5px;">
        </DatePicker>
      </div>
      <div class="form-item">
        <div class="inputBox"
             style="margin-right:60px;">
          <div class="title">融资类型</div>
          <Select v-model="params.fl[0]"
                  style="width:300px;">
            <Option v-for="item in typeList"
                    :value="item.value"
                    :key="item.value">{{ item.label }}</Option>
          </Select>
        </div>
        <div class="inputBox"
             style="margin-right:60px;">
          <div class="title">融资方式</div>
          <Select v-model="params.rzfs[0]"
                  style="width:300px;">
            <Option v-for="item in methodList"
                    :value="item.value"
                    :key="item.value">{{ item.label }}</Option>
          </Select>
        </div>
      </div>
      <Button type="info"
              style="width: 144px; display:block; margin:0 auto;"
              @click="getList">查询</Button>
    </div>
    <div class="middlePart">
      <span>已选条件：</span>
      <Tag type="border"
           v-if="params.qyjc"
           size="large"
           @on-close="params.qyjc=''"
           closable>企业名称: <span class="tabContent">
          {{params.qyjc}}
        </span></Tag>
      <Tag type="border"
           v-if="params.gpdm"
           size="large"
           @on-close="params.gpdm=''"
           closable>股票代码: <span class="tabContent">
          {{params.gpdm}}
        </span></Tag>
      <Tag type="border"
           v-if="params.fl[0]"
           size="large"
           @on-close="params.fl[0]=''"
           closable>融资类型: <span class="tabContent">
          {{params.fl[0]}}
        </span></Tag>
      <Tag type="border"
           v-if="params.rzfs[0]"
           size="large"
           @on-close="params.rzfs[0]=''"
           closable>融资方式: <span class="tabContent">
          {{params.rzfs[0]}}
        </span></Tag>
      <Tag type="border"
           v-if="params.ggrq[0]&&params.ggrq[1]"
           size="large"
           @on-close="params.ggrq=[]"
           closable>公告时间: <span class="tabContent">
          {{params.ggrq[0]}}至{{params.ggrq[1]}}
        </span></Tag>
      <Tag type="border"
           v-if="params.sjrzrq[0]&&params.sjrzrq[1]"
           size="large"
           @on-close="params.sjrzrq=[]"
           closable>融资日期: <span class="tabContent">
          {{params.sjrzrq[0]}}至{{params.sjrzrq[1]}}
        </span></Tag>
    </div>
    <div class="bottomPart">
      <Table border
                            tooltip-theme="light"
             :columns="stockColumns"
             :loading="tableLoading"
             :data="stockTable">
        <template slot-scope="{ row }"
                  slot="action">
          <Button type="primary"
                  size="small"
                  style="margin-right: 5px"
                  @click.stop="tableClick(row)">详情</Button>
        </template></Table>
      <div style="text-align: right; padding: 20px;">
        <Page :total="total"
              show-elevator
              @on-change="pageChange" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { getOtherData, getDetail } from '@/api/financial/other'

export default {
  name: 'trust',
  data () {
    return {
      params: {
        "current": 0,
        "fl": [''],
        "ggrq": [""],
        "gpdm": "",
        "qyjc": "",
        "qyqc": "",
        "rzfs": [""],
        "size": 0,
        "sjrzrq": [""]
      },
      methodList: [
        {
          value: '中地会',
          label: '中地会'
        }
      ],
      typeList: [
        {
          value: '1',
          label: '银行其他'
        },
        {
          value: '2',
          label: '融资产品'
        },
        {
          value: '3',
          label: '融资租赁'
        },
        {
          value: '4',
          label: '权益融资'
        },
        {
          value: '5',
          label: '其他'
        },
      ],
      total: 0,
      stockTable: [],
      tableLoading: false,
      stockColumns: [
        {
          title: '融资方式',
          key: 'rzfs'
        },
        {
          title: '股票代码',
          key: 'gpdm'
        },
        {
          title: '企业简称',
          key: 'qyjc'
        },
        {
          title: '公告日期',
          key: 'ggrq'
        },
        {
          title: '实际融资日期',
          key: 'sjrzrq'
        },
        {
          title: '融资状态',
          key: 'rzzt'
        },
        {
          title: '融资方式',
          key: 'rzfs'
        },
        {
          title: '融资规模 （亿元）',
          key: 'rzgmyy'
        },
        {
          title: '币种',
          key: 'bz'
        },
        {
          title: '融资利率',
          key: 'rzll'
        },
        {
          title: '期限',
          key: 'qx'
        },
        {
          title: '实际融资人',
          key: 'sjrzr'
        },
        {
          title: '股权比例',
          key: 'gqbl'
        },
        {
          title: '融资详情',
          slot: 'action',
          key: 'rzxq'
        }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  computed: {
    ...mapState([
      'activeDetail'
    ])
  },
  methods: {
    getTimeEnd (value) {
      this.$set(this.params.ggrq, 1, value)
    },
    getTimeStart (value) {
      this.$set(this.params.ggrq, 0, value)
    },
    getTimeEndrz (value) {
      this.$set(this.params.sjrzrq, 1, value)
    },
    getTimeStartrz (value) {
      this.$set(this.params.sjrzrq, 0, value)
    },
    tableClick (row) {
      getDetail({
        id: row.id
      })
        .then(info => {
          this.changeState({
            prop: 'activeDetail',
            value: info
          })
          localStorage.setItem('activeDetail',JSON.stringify(info))
          this.$router.push({
            path: `/Insight/financialMarket/financialProduct/Bond/otherDetail`
            // path: `/financialMarket/financialProduct/Bond/foreignDetail`
          });
        })
    },
    getList () {
      this.tableLoading = true;
      if (this.params.ggrq.every(item => !item)) {
        this.params.ggrq = []
      }
      if (this.params.sjrzrq.every(item => !item)) {
        this.params.sjrzrq = []
      }
      getOtherData(this.params)
        .then(data => {
          this.stockTable = data.records
          this.total = data.total
        }).finally(() => {
          this.tableLoading = false;
        })
    },
    pageChange (size) {
      this.params.current = size;
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";
.trust {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 20px;
}
.trust {
  .topPart {
    width: 100%;
    height: 260px;
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    border: 1px solid rgba(227, 227, 229, 1);
    padding: 20px 30px;
    .form-item {
      margin-bottom: 20px;
      @include flex(flex-start);
      .inputBox {
        @include flex(flex-start);
      }
      .title {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: rgba(102, 102, 102, 1);
        line-height: 19px;
        margin-right: 20px;
        word-break: keep-all;
      }
      .addIssue {
        position: relative;
        .seletcTypeBox {
          position: absolute;
          left: 0;
          top: 20px;
          height: 40px;
          display: none;
          overflow: hidden;
          background: #fff;
          border: 1px solid rgba(227, 227, 229, 1);
          padding: 10px;
          z-index: 100;
        }
        &:hover .seletcTypeBox {
          display: block;
        }
      }
    }
  }
  .middlePart {
    margin: 20px 0;
  }
}
</style>
